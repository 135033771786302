import React from "react";

const LRButton = ({ text, isBig, handleClick }: { text: string; isBig?: boolean; handleClick: React.MouseEventHandler<HTMLButtonElement> }) => {
	return (
		<button
			onClick={handleClick}
			className={`cursor-pointer lg:w-auto py-3 2xl:py-4 px-8 2xl:px-8 font-semibold bg-black text-white xl:rounded-[4px] rounded-lg ${
				isBig ? "text-base" : "text-xs 2xl:text-base"
			}`}
		>
			{text}
		</button>
	);
};

export default LRButton;
