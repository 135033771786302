import React from "react";
import LRNavigationBar from "../components/LRNavigationBar";
import Footer from "../sections/Footer";
import { useLocation } from "@gatsbyjs/reach-router";

const Layouts = ({ children }) => {
	const location = useLocation();

	if (location.pathname === "/sell/" || location.pathname === "/sell") return children;

	if (location.pathname === "/refresh/" || location.pathname === "/refresh") return children;

	return (
		<div className="min-h-screen">
			<header className="relative">
				<LRNavigationBar />
			</header>
			{children}
			<Footer />
		</div>
	);
};

export default Layouts;
