import { Link } from "gatsby";
import React from "react";

const Footer = () => {
  return (
    <footer className="mt-3 w-full bg-black px-6 pt-8 pb-10 lg:py-10 xl:px-24 2xl:px-36 space-y-6 2xl:space-y-8">
      <div className="space-y-8 lg:space-y-0 lg:flex lg:items-center lg:justify-between">
        <img src="/logoWhite.svg" alt="logo" />
        <div>
          <ul className="text-base font-normal text-white space-y-4 lg:space-y-0 lg:flex lg:items-center lg:justify-end xl:space-x-10 2xl:space-x-16">

            <li>
              <a href="/privacy" target='_blank'>Privacy Policy</a>
            </li>
            <li>
              <a href="/terms-of-use" target='_blank'>Terms of Use</a>
            </li>
            <li>
              <a href="/anti-money-laundering" target='_blank'>AML/CT Policy</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="space-y-4 text-white font-light text-base lg:space-y-0 lg:flex lg:items-center lg:justify-between">
        <p className="font-extralight text-gray-100">© 2024 LocalRamp. All rights reserved</p>
        <a href="mailto:business@localramp.co">
          <span className="font-light text-gray-100">
            Contact us: <b> business@localramp.co</b>
          </span>
        </a>

        <div className="flex items-center justify-start space-x-2 text-white">

          <a href="https://twitter.com/localramp" target="_blank">
            <img src="/icons/twitter.svg" alt="twitter" className="w-5 h-5" />
          </a>
          {/*
          <a href="https://www.instagram.com/localramp/" target="_blank">
            <img src="/icons/instagram.svg" alt="instagram" className="w-5 h-5" />
          </a>
          */}
          <a href="https://www.linkedin.com/company/localramp" target="_blank">
            <img src="/icons/linkedin.svg" alt="linkedin" className="w-5 h-5" />
          </a>
          <p>@localramp</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
