exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-anti-money-laundering-tsx": () => import("./../../../src/pages/anti-money-laundering.tsx" /* webpackChunkName: "component---src-pages-anti-money-laundering-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-refresh-tsx": () => import("./../../../src/pages/refresh.tsx" /* webpackChunkName: "component---src-pages-refresh-tsx" */),
  "component---src-pages-sell-tsx": () => import("./../../../src/pages/sell.tsx" /* webpackChunkName: "component---src-pages-sell-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */)
}

