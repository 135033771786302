import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { handleLoginClick, handleSignUpClick } from "../constants";
import LRButton from "./LRButton";

const LRNavigationBar = () => {
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

	const toggleBurgerMenu = () => {
		setIsMobileMenuOpen(!isMobileMenuOpen);
	};

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > 639) {
				setIsMobileMenuOpen(false);
			}
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div className="relative w-screen bg-white">
			<div
				className="p-6 xl:pb-4 xl:px-[72px] 2xl:px-[120px] 2xl:pb-5 2xl:pt-11 flex items-center justify-between gap-10 bg-white w-full fixed z-50"
				style={{
					background: isMobileMenuOpen ? "white" : "rgba(255, 255, 255, 0.6)",
					backdropFilter: isMobileMenuOpen ? "" : "blur(32px)",
				}}
			>
				<Link to="/">
					<div className="xl:w-[168px] 2xl:w-[238px] h-auto">
						<img src="/logo.svg" alt="logo" className="w-full" />
					</div>
				</Link>
				<div className="inline-block sm:hidden">
					<button onClick={toggleBurgerMenu} className="w-6 h-6">
						{isMobileMenuOpen ? (
							<img src="/icon-close.svg" alt="open" className="w-full h-full" />
						) : (
							<img src="/burgerMenu.svg" alt="menu" className="w-full h-full" />
						)}
					</button>
				</div>
				<div className="items-center justify-end hidden space-x-2 sm:flex">
					<a href="https://docs.localramp.co/" target="_blank" rel="noreferrer">
						<div className="flex sm:min-w-[172px] items-center justify-center space-x-1 xl:py-3 2xl:py-4 xl:px-4 2xl:px-8 font-semibold text-purple">
							<p className="text-xs 2xl:text-base">Open Documentation</p>
							<i className="ri-arrow-right-up-line 2xl:ri-lg"></i>
						</div>
					</a>
					<LRButton text={"Merchant Login"} handleClick={handleLoginClick} />
				</div>
			</div>
			{isMobileMenuOpen ? (
				<div className="fixed left-0 right-0 z-50 w-full bg-white shadow-lg rounded-b-3xl top-14">
					<div></div>
					<div className="">
						<ul className="py-6 space-y-5">
							<li className="">
								<button className="flex cursor-pointer sm:min-w-[172px] px-6 items-center justify-center space-x-1 xl:py-3 2xl:py-4 xl:px-4 2xl:px-8 font-semibold text-purple">
									<p className="text-base">Open Documentation</p>
									<i className="ri-arrow-right-up-line 2xl:ri-lg"></i>
								</button>
							</li>
							<button
								onClick={handleSignUpClick}
								className="flex sm:min-w-[172px] px-6 items-center justify-center space-x-1 xl:py-3 2xl:py-4 xl:px-4 2xl:px-8 font-semibold text-black"
							>
								<p className="text-base">Merchant Sign up</p>
							</button>
							<button
								onClick={handleLoginClick}
								className="flex sm:min-w-[172px] px-6 items-center justify-center space-x-1 xl:py-3 2xl:py-4 xl:px-4 2xl:px-8 font-semibold text-black"
							>
								<p className="text-base">Merchant Login</p>
							</button>
						</ul>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default LRNavigationBar;
